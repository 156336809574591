<script lang="ts" setup>
const { t } = useT();
const { initAndOpen } = useSupportChat();
const { isOpen } = useTaoModals();
const {
	unreadNotifications,
	readNotifications,
	showNotificationPopup,
	handleClickNotificationMessage,
	setNotificationsStatusAsRead,
	updateNotificationsStatusAsRead
} = useNotificationCenter({ isOpen });

const hasMessages = computed(() => unreadNotifications.value.length || readNotifications.value.length);

const handleClosePopup = () => {
	showNotificationPopup.value = false;
};

const handleClickOpenSupport = () => {
	dispatchGAEvent({
		event: "click_button",
		location: "widget",
		button_name: "support"
	});
	handleClosePopup();
	initAndOpen();
};

const onShowPopupHandler = () => {
	dispatchGAEvent({
		event: "tooltips",
		type: unreadNotifications.value.length ? "new" : "empty",
		location: "header",
		form_name: "notifications"
	});

	if (!unreadNotifications.value.length) {
		return;
	}

	const unreadOfferIds = unreadNotifications.value.reduce<number[]>((acc, item) => {
		if (item.type === "offer" && new Date(item.dateEnd).getTime() < new Date().getTime()) {
			acc.push(item.ncMessageId);
		}
		return acc;
	}, []);
	if (unreadOfferIds.length) {
		setNotificationsStatusAsRead(unreadOfferIds);
	}
};
const onHidePopupHandler = () => {
	if (!unreadNotifications.value.length) {
		return;
	}

	const unreadIds = unreadNotifications.value.reduce<number[]>((acc, item) => {
		if (!item.link) {
			acc.push(item.ncMessageId);
		}
		return acc;
	}, []);
	if (unreadIds.length) {
		updateNotificationsStatusAsRead(unreadIds);
	}
};

watch(showNotificationPopup, (value) => (value ? onShowPopupHandler() : onHidePopupHandler()));
</script>
<template>
	<Transition name="fade">
		<AOverlay
			v-if="showNotificationPopup"
			class="notification-overlay"
			:modifiers="['fixed']"
			@click="handleClosePopup"
		/>
	</Transition>
	<Transition name="slide">
		<div v-if="showNotificationPopup" class="notification-popup">
			<div class="header">
				<AText class="d-flex gap-2" as="div" type="h8">
					{{ t("InBox") }}

					<AText v-if="unreadNotifications.length" class="unread-count" type="x-small">
						{{ unreadNotifications.length }}
					</AText>
				</AText>
				<AIconButton variant="ghost" @click.prevent="handleClosePopup">
					<NuxtIcon class="close-icon" name="20/close" />
				</AIconButton>
			</div>
			<div class="content">
				<template v-if="hasMessages">
					<div v-if="unreadNotifications.length" class="unread-wrapper">
						<MNotificationCenterItem
							v-for="item in unreadNotifications"
							:key="`unread-${item.ncMessageId}`"
							:data="item"
							@click-message="handleClickNotificationMessage"
							@close-popup="handleClosePopup"
						/>
					</div>
					<div v-if="readNotifications.length">
						<AText class="title" as="p" type="small" :modifiers="['uppercase']">{{ t("Earlier") }}</AText>
						<MNotificationCenterItem
							v-for="item in readNotifications"
							:key="`read-${item.ncMessageId}`"
							:data="item"
							@click-message="handleClickNotificationMessage"
							@close-popup="handleClosePopup"
						/>
					</div>
				</template>
				<AText v-else as="p" class="no-messages" type="base" :modifiers="['center']">
					{{ t("Here will be your messages") }}
				</AText>
			</div>
			<div class="footer">
				<AButton variant="secondary" size="md" :modifiers="['wide']" @click="handleClickOpenSupport">
					<NuxtIcon class="support-icon" name="16/support" />
					{{ t("Customer care") }}
				</AButton>
				<AText type="x-small" :modifiers="['center']">
					{{ t("Messages older than 14 days are deleted for you.") }}
				</AText>
			</div>
		</div>
	</Transition>
</template>
<style scoped lang="scss">
.notification-overlay {
	--a-overlay-cursor: default;
	display: block;
	z-index: 10004;
}

.notification-popup {
	width: 360px;
	height: 100dvh;
	position: fixed;
	z-index: 10004;
	top: 0;
	right: 0;
	background: var(--gray-800);
	display: flex;
	flex-direction: column;

	@include media-breakpoint-down(lg) {
		width: 100%;
	}

	&:deep(svg) {
		filter: none !important;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 12px 16px;
		border-radius: 16px 16px 0 0;
		border-bottom: 1px solid var(--gray-700);
	}

	.unread-count {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 20px;
		height: 20px;
		padding: 2px;
		border-radius: 99px;
		background: var(--danger-300);
		color: var(--gray-50);
	}

	.close-icon {
		cursor: pointer;
		font-size: 16px;
		color: var(--gray-200);
	}

	.support-icon {
		font-size: 16px;
		color: var(--gray-50);
		margin-right: 4px;
		margin-bottom: 2px;
	}

	.content {
		flex-grow: 1;
		overflow: auto;

		&::-webkit-scrollbar {
			display: none;
		}

		.title {
			padding: 4px 16px;
			margin-bottom: 0;
			color: var(--gray-200);
		}
	}

	.unread-wrapper {
		background-color: var(--gray-900);
	}

	.no-messages {
		padding-top: 64px;
		color: var(--gray-200);
	}

	.footer {
		display: flex;
		padding: 16px;
		flex-direction: column;
		align-items: center;
		gap: 8px;
		background: var(--gray-800);
		box-shadow: 0 -32px 32px 0 rgba(0, 0, 0, 0.24);
		color: var(--gray-400);
	}
}

.fade-enter-active,
.fade-leave-active,
.slide-enter-active,
.slide-leave-active {
	transition: all 250ms ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.slide-enter-active {
	transform: translateX(100%);
}
.slide-enter-to {
	transform: translateX(0);
}
.slide-leave-active {
	transform: translateX(0);
}
.slide-leave-to {
	transform: translateX(100%);
}
</style>
